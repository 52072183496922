import BCardCode from '@core/components/b-card-code'
import {
  BNav, BNavItem, BNavItemDropdown, BDropdownDivider, BDropdownItem, BFormInput, BRow, BCol,
  BFormGroup, BButton, BCard, BDropdown, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay, BSidebar, VBToggle,
} from 'bootstrap-vue'

import DateRangePicker from '@core/components/ez-daterange-picker/components/DateRangePicker.vue'

import dayjs from 'dayjs'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import DxButton from 'devextreme-vue/button'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter'
import { exportDataGrid } from 'devextreme/excel_exporter'

import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'

import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'

import { createStore } from 'devextreme-aspnet-data-nojquery'
// import { codeBasic } from './code'
// import MasterDetailGrid from './MasterDetailGrid.vue'
import 'devextreme/dist/css/dx.light.css'
import '@core/scss/vue/libs/vue-select.scss'

const dataGridRef = 'dataGrid'

const url = 'https://js.devexpress.com/Demos/Mvc/api/DataGridWebApi'

const dataSource = createStore({
  key: 'OrderID',
  loadUrl: `${url}/Orders`,
  insertUrl: `${url}/InsertOrder`,
  updateUrl: `${url}/UpdateOrder`,
  deleteUrl: `${url}/DeleteOrder`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true }
  },
})

const customersData = createStore({
  key: 'Value',
  loadUrl: `${url}/CustomersLookup`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true }
  },
})

const shippersData = createStore({
  key: 'Value',
  loadUrl: `${url}/ShippersLookup`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: true }
  },
})

export default {
  name: 'Visitor',
  components: {
    flatPickr,
    BSidebar,
    BCardCode,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    DateRangePicker,
    BButton,
    BCard,
    BDropdown,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    // MasterDetailGrid,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      navbarColors: ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'],
      mask: {
        startDate: dayjs().add(2, 'day').format('DD/MM/YYYY hh:mm A'),
        endDate: dayjs().add(8, 'day').format('DD/MM/YYYY hh:mm A'),
      },
      dateRangeValue: {
        startDate: dayjs().add(2, 'day').toDate(),
        endDate: dayjs().add(8, 'day').toDate(),
      },
      ranges: ezDateRange(),
      yearStart: parseInt(dayjs().subtract(5, 'year').format('YYYY')),
      yearEnd: parseInt(dayjs().add(14, 'year').format('YYYY')),
      date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,

      tempClass: '',
      small: false,
      outlined: false,
      striped: false,
      bordered: true,
      borderless: false,
      hover: true,
      dark: false,
      resize: true,
      reOrder: true,
      headVariant: null,
      configTable: [
        {
          caption: this.$t('VISITOR.BAGTAG'),
          field: 'CustomerID',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('VISITOR.NAME'),
          field: 'OrderDate',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('VISITOR.GOLF_CLASS'),
          field: 'Freight',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('VISITOR.GROUP_NAME'),
          field: 'ShipCountry',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('VISITOR.NOTE'),
          field: 'ShipVia',
          isShow: true,
          cellTemplate: 'course-apply',
          alignment: 'left',
        },
        {
          caption: this.$t('VISITOR.CHECK_IN'),
          field: 'ShipVia',
          isShow: true,
          cellTemplate: 'golf-class',
          alignment: 'left',
        },
        {
          caption: this.$t('VISITOR.CHECK_OUT'),
          field: 'ShipVia',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('VISITOR.AMOUNT'),
          field: 'ShipVia',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('VISITOR.INVOICE'),
          field: 'ShipVia',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: '',
          field: '',
          isShow: true,
          cellTemplate: 'action',
          alignment: 'left',
        },
      ],
      groupCol: null,
      showCols: ['CustomerID', 'OrderDate', 'Freight', 'ShipCountry'],
      option: [
        { title: 'Customer', field: 'CustomerID' },
        { title: 'OrderDate', field: 'OrderDate' },
        { title: 'Freight', field: 'Freight' },
        { title: 'ShipCountry', field: 'ShipCountry' },
        { title: 'ShipVia', field: 'ShipVia' },
      ],

      url,
      customersData,
      shippersData,
      dataSource,
      dataGridRef,
      displayModes: [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }],
      displayMode: 'full',
      pageSizes: [5, 10, 'all'],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      allMode: 'allPages',
    //   checkBoxesMode: 'onClick',
    }
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  watch: {
    dateRangeValue(value) {
      this.mask.startDate = dayjs(value.startDate).format('DD/MM/YYYY hh:mm A')
      this.mask.endDate = dayjs(value.endDate).format('DD/MM/YYYY hh:mm A')
    },
    showCols(selected) {
      // Level 1
      this.configTable.forEach(x => {
        if (selected.includes(x.field)) {
          x.isShow = true
        } else {
          x.isShow = false
        }

        // Level 2
        // if (x.bands.length > 0) {
        //   x.bands.forEach(y => {
        //     if (selected.includes(y.field)) {
        //       y.isShow = true
        //     } else {
        //       y.isShow = false
        //     }
        //   })
        // }
      })
    },
    groupCol(selected) {
      this.$refs[this.dataGridRef].instance.clearGrouping()
      this.$refs[this.dataGridRef].instance.columnOption(selected, 'groupIndex', 0)
    },
    small(value) {
      if (value) {
        if (this.dark) {
          this.tempClass = 'ez-dx-grid-small ez-dx-grid-dark'
        } else {
          this.tempClass = 'ez-dx-grid-small'
        }
      } else if (this.dark) {
        this.tempClass = 'ez-dx-grid-dark'
      } else {
        this.tempClass = ''
      }
    },
    dark(value) {
      if (value) {
        if (this.small) {
          this.tempClass = 'ez-dx-grid-small ez-dx-grid-dark'
        } else {
          this.tempClass = 'ez-dx-grid-dark'
        }
      } else if (this.small) {
        this.tempClass = 'ez-dx-grid-small'
      } else {
        this.tempClass = ''
      }
    },
  },
  methods: {
    _dayjs: dayjs,
    updateRangeStartDate(startDate) {
      // Tuỳ theo format date để chuyển sang date mặc định: định dạng dd/mm/yyyy hh:mm A nên cần chuyển sang mm/dd/yyyy hh:mm A sau đó chuyển sang date
      const dateParts = startDate.split('/')
      const hourParts = dateParts[2].split(' ')
      const tmp = `${dateParts[1]}/${dateParts[0]}/${hourParts[0]} ${hourParts[1]} ${hourParts[2]}`
      this.dateRangeValue.startDate = dayjs(tmp).toDate()
      // Xoá lịch
      this.$refs.picker.togglePicker(false, true)
      // Build lại lịch để hiển thị đúng khoảng thời gian đã chọn
      setTimeout(() => {
        this.$refs.picker.togglePicker(true, true)
      }, 1)
    },
    updateRangeEndDate(endDate) {
      // Tuỳ theo format date để chuyển sang date mặc định: định dạng dd/mm/yyyy hh:mm A nên cần chuyển sang mm/dd/yyyy hh:mm A sau đó chuyển sang date
      const dateParts = endDate.split('/')
      const hourParts = dateParts[2].split(' ')
      const tmp = `${dateParts[1]}/${dateParts[0]}/${hourParts[0]} ${hourParts[1]} ${hourParts[2]}`
      this.dateRangeValue.endDate = dayjs(tmp).toDate()
      // Xoá lịch
      this.$refs.picker.togglePicker(false, true)
      // Build lại lịch để hiển thị đúng khoảng thời gian đã chọn
      setTimeout(() => {
        this.$refs.picker.togglePicker(true, true)
      }, 1)
    },
    customText(elem, data) {
      const formattedValue = data.displayValue
      if (data.column.dataField == 'CustomerID') {
        elem.append(`${formattedValue} - Custom text customer`)
      } else {
        elem.append(data.displayValue)
      }
    },
    exportGrid() {
      const pdfDoc = new jsPDF('p', 'pt', 'a4')
      exportDataGridToPdf({
        jsPDFDocument: pdfDoc,
        component: this.dataGrid,
      }).then(() => {
        pdfDoc.setFontSize(12)
        const pageCount = pdfDoc.internal.getNumberOfPages()
        for (let i = 1; i <= pageCount; i++) {
          pdfDoc.setPage(i)
          const { pageSize } = pdfDoc.internal
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          const header = 'Report 2021'
          const footer = `Page ${i} of ${pageCount}`
          const logo = 'iVBORw0KGgoAAAANSUhEUgAAAHUAAAAcCAMAAABoIQAcAAAAw1BMVEUAAAAQSqEQR54QSZ8QSZ8Aod0AoNwAn9sRR50RRZr/7Un/808RS58TRpr/6UMMX64RS6AbM4H+yCEcMoKJglj/zSj7xyQRSp8AoNwRR5wRSJ0RSZ4VQZMUQpYSRpoTRJj/6UP/8EsWPpAAmNgXOosaNIQTRJf/8k3/5T8Andr+3jkAk9T/7kj/4jz+2jQAjtL/7Eb+1zABic4Hdr/+0y3+0CoDhMoEfcVQYnb+zCXHv1bFuVAJZrRUYXOLjGHErkfEqUEir4bvAAAAF3RSTlMAf0D+v3+/QP7+QL+/v3/+v7+/f39/QKzJvtMAAAJISURBVEjH7ZXpctowFIUtWTZLm3TvxRbGAQJJmjS00LAk3d7/qXoXScjTYQI/yo8mZ7B0pdH4m3NkieRZT0hatUsL8FIlx5M+KYqytDYFAJ0cSbogaMlQ9JscRSedAWFtCYJ9nRxBespU8poC/cAk/1y6mnYKVBv3FUhIPgirldIHL6iqeiAJI5WQ+Oz1SSkFRinsAVSyW9GC00+3X7/dffiIUxE1BS/zONOv3Jvavbz8zNjN2/f9alr7fQWUI6vHYmUiLdyX2j07Qypi7zabdZ+xRbuNR4eQ/kkayrJWc8LnoaMAlXJ03kaNA+40L+jefEGsmP2N0Kqu/cfkI361WGQRIu+hcuRixyVbbVrhKamwC7PGR3c6vCEqY9d9xNYccEg4Xbh3B6NhHKjBX6AKlMqYasLscOjN3v4ajYjaGXDCVrKd97xcqlxlbtjiUUwN79eMjqg0Nq68YCx/UGukVtWUzJbtUhJeXvWCPDVnwxmnzf3fVIncNKjceCph2ezP8xEnzEcHzaY2heX4aovNo4CFmtPkbqraSX2BWM744Ryp5NWd1xTNrq7H48hsSzLNM5LL15HgMGr3QjJ+mCA1JFyQVVhdM7VpVrrtppL8X6JuUmEnFc0S9n6CVEo4UK1dzZpUYeRsNs9dhRKzRim+m6iS1mDDVKWoiUvCDu9/TCIq3/7WLuazGVEjrMQqokIUDqL3BtpPiHmkO5vRVffuDTKZili/r9+Xc6I2zWbhxGbxLYHSYpcqgw6FoLY7bZT2JWD5rP9SfwAlY2dKPlPjkQAAAABJRU5ErkJggg=='

          // Add logo
          pdfDoc.addImage(logo, 'JPEG', 40, 10, 50, 25)

          // Header
          pdfDoc.text(header, 120, 20, { baseline: 'top' })

          // Footer
          pdfDoc.text(footer, pageWidth / 2 - (pdfDoc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' })
        }
      }).then(() => {
        pdfDoc.save('Customers.pdf')
      })
    },
    onExportingExcel() {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Employees')
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = 'Country Area, Population, and GDP Structure'
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)

        footerRow.getCell(1).value = 'www.wikipedia.org'
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx')
        })
      })
    },
  },
}
